.advantages-hero {
  &__title{
    display: block;
    text-align: center;
  }

  &__container{
    overflow: hidden;
    background-color: #1B3A6F;
    border-radius: 16px;
    position: relative;
  }

  &__image-container{
    position: absolute;
    z-index: 2;
  }

  &__picture{
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__map-picture{
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__map-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__map, &__map-middle, &__map-small{
    position: absolute;
    z-index: 1;
  }

  &__description{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }
}

@include respond-up('large') {
  .advantages-hero {
    &__container{
      padding: 70px 0 105px 55px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__title-icon{
      margin-bottom: 59px;
    }

    &__description{
      max-width: 375px;
    }

    &__image-container{
      width: 564px;
      height: 419px;
      bottom: 0;
      right: 35px;
    }

    &__map{
      top: 0;
      right: 0;
    }
  }
}

@include respond-up('medium') {
  .advantages-hero {
    &__title{
      margin-bottom: 30px;
    }
  }
}

@include respond('medium') {
  .advantages-hero {
    &__container{
      padding: 50px 50px 0 50px;
      height: calc(683/768 * 100vw);
      display: flex;
    }

    &__title-icon{
      margin-right: calc(154/768 * 100vw);
      svg{
        width: calc(170/768 * 100vw);
        height: calc(124/768 * 100vw);
      }
    }

    &__description{
      font-size: calc(17/768 * 100vw);
    }

    &__image-container{
      width: calc(564/768 * 100vw);
      height: calc(419/768 * 100vw);
      bottom: 0;
      left: calc(102/768 * 100vw);
    }

    &__map{
      left: calc(56/768 * 100vw);
      top: calc(197/768 * 100vw);
      width: calc(668/768 * 100vw);
      height: calc(330/768 * 100vw);
    }
  }
}

@include respond-down('medium') {
  .advantages-hero {

  }
}

@include respond-down('small') {
  .advantages-hero {
    &__title{
      margin-bottom: 25px;
    }

    &__container{
      padding: 30px 25px 0 25px;
      height: calc(580/375 * 100vw);
      display: flex;
      flex-direction: column;
    }

    &__title-icon{
      margin-bottom: calc(30/375 * 100vw);
      svg{
        width: calc(138/375 * 100vw);
        height: calc(102/375 * 100vw);
      }
    }

    &__description{
      font-size: calc(16/375 * 100vw);
    }

    &__image-container{
      width: calc(330/375 * 100vw);
      height: calc(253/375 * 100vw);
      bottom: -5px;
      left: 0;
    }

    &__map{
      top: calc(234/375 * 100vw);
      left: 0;
      width: calc(510/375 * 100vw);
      height: calc(250/375 * 100vw);
    }
  }
}