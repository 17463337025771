:root {
  // Максимальная ширина строки (строка = контейнер со спейсерами)
  --max-row-width: 1140px;

  // Расстояние между колонками - задается динамически по разрешениям - смотри _container.scss
  --grid-gap: 1px;

  // Отступ от края экрана до строки (строка = контейнер со спейсерами)
  --grid-indent: 0px;

  // Отступ от края строки до колонок (строка = контейнер со спейсерами)
  --grid-spacer: 1px;

  // Отступ от края контейнера до колонок
  --grid-spacer-and-indent: calc(var(--grid-indent) + var(--grid-spacer));

  // Кол-во колонок
  --grid-columns-number: 1;

  // Ширина одной колонки - задается динамически по разрешениям - смотри _container.scss
  --grid-column: calc((100vw - 2 * var(--grid-spacer-and-indent) - (var(--grid-columns-number) - 1) * var(--grid-gap)) / var(--grid-columns-number));

  // Ширина колонки
  --grid-column1: var(--grid-column);
  --grid-column2: calc((var(--grid-column) * 2) + var(--grid-gap));
  --grid-column3: calc((var(--grid-column) * 3) + (var(--grid-gap) * 2));
  --grid-column4: calc((var(--grid-column) * 4) + (var(--grid-gap) * 3));
  --grid-column5: calc((var(--grid-column) * 5) + (var(--grid-gap) * 4));
  --grid-column6: calc((var(--grid-column) * 6) + (var(--grid-gap) * 5));
  --grid-column7: calc((var(--grid-column) * 7) + (var(--grid-gap) * 6));
  --grid-column8: calc((var(--grid-column) * 8) + (var(--grid-gap) * 7));
  --grid-column9: calc((var(--grid-column) * 9) + (var(--grid-gap) * 8));
  --grid-column10: calc((var(--grid-column) * 10) + (var(--grid-gap) * 9));
  --grid-column11: calc((var(--grid-column) * 11) + (var(--grid-gap) * 10));
  --grid-column12: calc((var(--grid-column) * 12) + (var(--grid-gap) * 11));
  --grid-column13: calc((var(--grid-column) * 13) + (var(--grid-gap) * 12));
  --grid-column14: calc((var(--grid-column) * 14) + (var(--grid-gap) * 13));
  --grid-column15: calc((var(--grid-column) * 15) + (var(--grid-gap) * 14));
  --grid-column16: calc((var(--grid-column) * 16) + (var(--grid-gap) * 15));
  --grid-column17: calc((var(--grid-column) * 17) + (var(--grid-gap) * 16));
  --grid-column18: calc((var(--grid-column) * 18) + (var(--grid-gap) * 17));
  --grid-column19: calc((var(--grid-column) * 19) + (var(--grid-gap) * 18));
  --grid-column20: calc((var(--grid-column) * 20) + (var(--grid-gap) * 19));
  --grid-column21: calc((var(--grid-column) * 21) + (var(--grid-gap) * 20));


}

//@media (min-width: 1200px) {
//  :root {
//    --grid-indent: calc((100vw - var(--max-row-width)) / 2);
//  }
//}


@include respond-up('large') {
  :root {
    --grid-gap: 16px;
    --grid-spacer: 20px;
    --grid-columns-number: 21;
    --grid-indent: 0px;
  }
}

@include respond('medium') {
  :root {
    --grid-gap: 16px;
    --grid-spacer: 22px;
    --grid-columns-number: 6;
    --grid-indent: 0px;
  }
}

@include respond-down('small') {
  :root {
    --grid-gap: 12px;
    --grid-spacer: 18px;
    --grid-columns-number: 4;
    --grid-indent: 0px;
  }
}
