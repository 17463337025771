.recall-form {
  background-color: var(--dark-blue);

  &__relative-container{
    position: relative;
    height: 100%;
    width: 100%;
  }

  &__title{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }

  &__description{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }

  &__field{
    input{
      background: #F3F3F6;
      border-radius: 10px;
      padding-left: 22px;
    }

    label{
      display: none;
    }
  }

  &__data{
    background: #fff;
    border-radius: 16px;
  }

  &__button{
    color: #fff;
    background: #3066A2;
    border-radius: 10px;
    text-align: center;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    text-transform: uppercase;
  }

  &__factory{
    position: absolute;
  }

  &__success-title{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #3066A2;
  }

  &__success-description{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #676767;
  }

  &__success-inner{
    display: flex;
  }
}

@include respond-up('large') {
  .recall-form {
    margin-top: 80px;
    &__relative-container{
      padding-top: 68px;
      padding-bottom: 177px;
    }
    &__inner{
      display: flex;
      justify-content: space-between;
    }

    &__title{
      margin-bottom: 15px;
    }

    &__heading{
      width: 373px;
    }

    &__data{
      width: 535px;
      border-radius: 16px;
      padding: 30px 25px;
    }

    &__button-wrap{
      width: 153px;
      margin-left: 15px;
    }

    &__button{
      padding: 18px 30px;
      cursor: pointer;
      transition: background-color .3s;
      &:hover{
        background-color: #144E8E;
      }
    }

    &__factory{
      bottom: -5px;
      left: 0;
      right: 0;
    }

    &__success{
      top: 67px;
      right: 0;
      width: 535px;
      padding: 30px 30px 76px 25px;
    }

    &__success-icon{
      margin-right: 25px;
    }

    &__success-title{
      margin-bottom: 13px;
    }
  }
}

@include respond-up('medium') {
  .recall-form {
    &__fields{
      display: flex;
      gap: 16px;
    }

    &__field{
      flex: 50%;
    }

    &__bottom{
      display: flex;
      justify-content: space-between;
    }

    &__success-text{
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond('medium') {
  .recall-form {
    margin-top: 70px;
    &__title{
      padding-top: 35px;
      padding-bottom: 15px;
      font-size: 26px;
    }

    &__inner{
      padding-bottom: 179px;
    }

    &__factory{
      &.large{
        display: none;
      }
      &.medium{
        bottom: -5px;
      }
      &.small{
        display: none;
      }
    }

    &__data{
      padding: 30px 25px;
    }

    &__description{
      font-size: 16px;
      padding-bottom: 35px;
    }

    &__button-wrap{
      width: 153px;
      margin-left: 15px;
    }

    &__button{
      padding: 18px 30px;
    }

    &__success{
      display: flex;
      top: 67px;
      left: 0;
      right: 0;
      padding: 127px 30px 76px 25px;
    }

    &__success-icon{
      margin-right: 25px;
    }

    &__success-title{
      margin-bottom: 13px;
    }
  }
}

@include respond-down('medium') {
  .recall-form {
    overflow: hidden;
  }
}

@include respond-down('small') {
  .recall-form {
    margin-top: 40px;
    &__title{
      font-size: 22px;
      padding-top: 35px;
      padding-bottom: 15px;
    }

    &__description{
      font-size: 15px;
      padding-bottom: 30px;
    }

    &__factory{
      &.large{
        display: none;
      }
      &.medium{
        display: none;
      }
      &.small{
        bottom: -5px;
      }
    }

    &__inner{
      padding-bottom: 240px;
    }

    &__data{
      padding: 30px 25px;
    }

    &__description{
      font-size: 16px;
      padding-bottom: 35px;
    }

    &__bottom{
      padding-top: 15px;
      display: flex;
      flex-direction: column;
    }
    &__button-wrap{
      margin-top: 25px;
      width: 153px;
      align-self: flex-end;
    }

    &__button{
      padding: 18px 30px;
    }

    &__success{
      display: flex;
      top: 67px;
      left: 0;
      right: 0;
      padding: 140px 30px 76px 25px;
    }

    &__success-inner{
      flex-direction: column;
    }
    &__success-icon{
      margin-right: 25px;
    }

    &__success-title{
      margin-bottom: 13px;
    }
  }
}