.usage {
  &__title{
    display: block;
  }

  &__container{
    display: grid;
  }

  &__item{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      background: linear-gradient(358.85deg, #2A2A2F -14.86%, rgba(42, 42, 47, 0) 87.76%);
    }
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item-title{
    position: absolute;
    z-index: 2;
    color: #FFFFFF;
  }

  &__add{
    border-radius: 10px;
    background-color: #F3F3F6;
    display: grid;
  }

  &__add-icon{
    background-color: #3066A2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__add-text{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
    &_blue{
      color: rgba(48, 102, 162, 1);
    }
  }
}

@include respond-up('large') {
  .usage {
    padding-top: 90px;
    &__container{
      margin-bottom: 19px;
    }

    &__item{
      width: 355px;
      height: 228px;
    }

    &__item-title{
      bottom: 25px;
      left: 20px;
      right: 20px;
    }

    &__add{
      padding: 18px 26px;
      grid-template-columns: 85px 1fr;
      grid-column-gap: 50px;
    }

    &__add-icon{
      width: 85px;
      height: 78px;
      svg{
        width: 38px;
        height: 38px;
      }
    }
  }
}

@include respond-up('medium') {
  .usage {
    &__title{
      margin-bottom: 25px;
    }
    &__container{
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;
    }
  }
}

@include respond('medium') {
  .usage {
    padding-top: 70px;
    &__container{
      margin-bottom: 16px;
    }
    &__item{
      padding-bottom: calc(231/1139 * 100vw);
    }
    &__item-title{
      bottom: 20px;
      left: 15px;
      right: 15px;
    }

    &__add{
      padding: 26px 30px;
      grid-template-columns: 76px 1fr;
      grid-column-gap: 30px;
    }

    &__add-icon{
      width: 76px;
      height: 72px;
      svg{
        width: 32px;
        height: 32px;
      }
    }
  }
}

@include respond-down('medium') {
  .usage {

  }
}

@include respond-down('small') {
  .usage {
    padding-top: 50px;
    &__container{
      margin-bottom: 10px;
      grid-row-gap: 10px;
    }
    &__title{
      margin-bottom: 20px;
    }
    &__item{
      padding-bottom: calc(339/767 * 100vw);
    }
    &__item-title{
      bottom: 22px;
      left: 20px;
      right: 20px;
    }

    &__add{
      padding: 20px;
      grid-template-columns: 44px 1fr;
      grid-column-gap: 18px;
    }

    &__add-icon{
      width: 44px;
      height: 40px;
      svg{
        width: 20px;
        height: 20px;
      }
    }

    &__add-text{
      font-size: 16px;
    }
  }
}