.advantages {
  &__container{
    color: #fff;
    border-radius: 16px;
    &:first-child{
      background-color: #1B3A6F;
    }
    &:last-child{
      background-color: #3066A2;
      margin-top: -25px;
    }
  }
  &__list{
    display: grid;
  }

  &__title{
    font-weight: 700;
    font-size: 28px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
  }

  &__item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }

  &__item-title{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }
}

@include respond-up('large') {
  .advantages {
    &__title{
      padding-bottom: 30px;
    }

    &__list{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 71px;
    }

    &__item{
      padding: 16px 0;
    }


  }
}

@include respond-up('medium') {
  .advantages {
    &__container{
      &:first-child{
        padding: 40px 40px 65px 40px;
      }

      &:last-child{
        padding: 40px;
      }
    }

    &__item-title{
      margin-left: 27px;
    }
  }
}

@include respond('medium') {
  .advantages {
    &__title{
      padding-bottom: 25px;
    }
  }
}

@include respond-down('medium') {
  .advantages {
    &__list{
      display: flex;
      flex-direction: column;
    }

    &__item{
      padding: 12px 0;
    }
  }
}

@include respond-down('small') {
  .advantages {
    &__title{
      font-size: 22px;
      padding-bottom: 20px;
      text-align: center;
    }
    &__container{
      &:first-child{
        padding: 25px 20px 45px 20px;
      }

      &:last-child{
        padding: 25px;
      }
    }
    &__item-title{
      margin-left: 20px;
    }

    &__item-title{
      font-size: 14px;
    }
  }
}