.modal-form {
  &__title{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #2A2A2F;
  }

  &__subtitle{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #676767;
  }

  &__field{
    input{
      background: #F3F3F6;
      border-radius: 10px;
      padding-left: 22px;
    }

    label{
      display: none;
    }
  }

  &__button{
    border-radius: 10px;
    background-color: #3066A2;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
  }
}

@include respond-up('large') {
  .modal-form {
  }
}

@include respond-up('medium') {
  .modal-form {
    &__title{
      margin-bottom: 18px;
    }

    &__subtitle{
      margin-bottom: 30px;
    }

    &__fields-wrap{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
      margin-bottom: 25px;
    }

    &__bottom{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__button{
      padding: 18px 30px;
      transition: background-color .3s;
      &:hover{
        background-color: #144E8E;
      }
    }
  }
}

@include respond('medium') {
  .modal-form {

  }
}

@include respond-down('small') {
  .modal-form {
    &__title{
      margin-bottom: 15px;
    }

    &__subtitle{
      margin-bottom: 25px;
    }

    &__fields-wrap{
      display: grid;
      grid-row-gap: 10px;
      margin-bottom: 15px;
    }

    &__policy{
      margin-bottom: 25px;
    }

    &__button{
      padding: 16px 30px;
    }

    &__button-wrap{
      display: flex;
      justify-content: flex-end;
    }
  }
}