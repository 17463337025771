.products {
  &__white-bg{
    background-color: #FFFFFF;
  }
}

@include respond-up('large') {
  .products {

  }
}

@include respond-up('medium') {
  .products {

  }
}

@include respond('medium') {
  .products {

  }
}

@include respond-down('medium') {
  .products {

  }
}

@include respond-down('small') {
  .products {

  }
}