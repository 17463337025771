.hero {
  &__image-container{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: linear-gradient(180deg, rgba(10, 18, 31, 0.62) 0%, rgba(10, 18, 31, 0) 100%);
    }
  }

  &__picture{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__relative-container{
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__text-container{
    display: grid;
    grid-template-rows: repeat(4, min-content);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    color: #ffffff;
    height: 100%;
    justify-content: center;
  }

  &__title{
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: -0.02em;
  }

  &__subtitle{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    letter-spacing: -0.02em;
  }

  &__button{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
    max-width: fit-content;
    padding-bottom: 8px;
  }

  &__logo{
    grid-row: 1 / 2;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title{
    grid-row: 2 / 3;
  }

  &__subtitle{
    grid-row: 3 / 4;
  }

  &__button{
    grid-row: 4 / 5;
  }
}

@include respond-up('large') {
  .hero {
    padding-top: 50px;
    &__image-container, &__relative-container{
      height: 560px;
    }

    &__text-container{
      grid-template-columns: repeat(21, 1fr);
      grid-column-gap: 16px;
    }

    &__logo{
      padding-top: 56px;
      grid-column: 1 / 22;
    }

    &__title{
      padding-top: 32px;
      grid-column: 2 / 21;
      font-size: 55px;
    }

    &__title-container{
      margin-left: 125px;
    }

    &__subtitle{
      padding-top: 18px;
      grid-column: 6 / 17;
    }

    &__button{
      padding-top: 30px;
      grid-column: 6 / 11;
    }

    &__link{
      &.underline{
        .hero{
          &__link-text{
            &::after {
              transition: transform 0.4s var(--teaser-transition-func) 0.4s;
              transform: scaleX(1);
            }

            &::before {
              transition: transform 0.4s var(--teaser-transition-func);
              transform: scaleX(0);
            }
          }
        }
      }
    }

    &__link-text{
      position: relative;
      display: block;
      padding-bottom: 8px;
      &:before {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        background-color: #FFFFFF;
        bottom: 0;
        transform-origin: right;
        //transition: transform 0.4s ease-out;
      }

      &:after {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        background-color: #FFFFFF;
        bottom: 0;
        transform: scaleX(0);
        transform-origin: left;
        //transition: transform 0.4s ease-out;
      }
    }
  }
}

@include respond-up('medium') {
  .hero {
    &__title{
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond('medium') {
  .hero {
    &__image-container, &__relative-container{
      height: 813px;
    }

    &__text-container{
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 16px;
    }

    &__logo{
      padding-top: 70px;
      grid-column: 1 / 7;
    }

    &__title{
      padding-top: 40px;
      grid-column: 1 / 7;
      font-size: calc(44/768 * 100vw);
    }

    &__title-container{
      margin-left: 125px;
    }

    &__subtitle{
      padding-top: 30px;
      grid-column: 2 / 6;
      font-size: calc(17/768 * 100vw);
    }

    &__button{
      padding-top: 30px;
      grid-column: 2 / 6;
    }
  }
}

@include respond-down('medium') {
  .hero {

  }
}

@include respond-down('small') {
  .hero {
    &__image-container, &__relative-container{
      height: 510px;
    }

    &__text-container{
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 16px;
    }

    &__logo{
      display: none;
    }

    &__title{
      padding-top: 52px;
      grid-column: 1 / 5;
      font-size: calc(min(8vw, 40px));
    }

    &__title-container{
      display: flex;
      flex-direction: column;
    }

    &__subtitle{
      padding-top: 20px;
      grid-column: 1 / 5;
      font-size: calc(min(4vw, 20px));
      max-width: 500px;
    }

    &__button{
      padding-top: 33px;
      font-size: 13px;
      grid-column: 1 / 5;
    }
  }
}

