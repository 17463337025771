body {
  font-size: var(--body-font-size);
  background-color: var(--body-color);
  font-family: var(--font);
  line-height: var(--body-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, button, form {
  font-family: var(--font);
}