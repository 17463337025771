.prices {
  background: #3066A2;
  &__title{
    color: #FFFFFF;
    display: block;
  }

  &__price{
    border-radius: 16px;
    background: #FFFFFF;
    overflow: hidden;
  }

  &__price-head{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1B3A6F;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }

  &__price-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__price-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__price-value{
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #2A2A2F;
    margin-bottom: 4px;
  }

  &__price-description{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #676767;
  }

  &__list{
    display: grid;
    margin-bottom: 20px;
  }

  &__recall{
    display: flex;
    justify-content: center;
  }

  &__recall-link{
    background-color: #1B3A6F;
    border-radius: 10px;
    align-self: center;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-transform: uppercase;
    display: flex;
    padding: 13px 21px;
  }
}

@include respond-up('large') {
  .prices {
    padding: 80px 0;
    &__price-head{
      padding: 22px 25px;
    }

    &__price-container{
      padding: 30px 0;
    }

    &__price-item{
      margin-bottom: 30px;
      &:last-child{
        margin: 0;
      }
    }

    &__price-value{
      font-size: 28px;
    }

    &__recall-link{
      transition: background-color .3s;
      &:hover{
        background: #041D47;
      }
    }
  }
}

@include respond-up('medium') {
  .prices {
    &__list{
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 19px;
      &._grid-2{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
      }
    }

    &__title{
      margin-bottom: 25px;
    }
  }
}

@include respond('medium') {
  .prices {
    padding: 50px 0;
    &__price-head{
      padding: 20px 25px;
    }

    &__price-value{
      font-size: 24px;
    }

    &__price-item{
      margin-bottom: 20px;
      &:last-child{
        margin: 0;
      }
    }
  }
}

@include respond-down('medium') {
  .prices {
    &__price-head{
      font-size: 18px;
    }

    &__price-container{
      padding: 25px 0;
    }
  }
}

@include respond-down('small') {
  .prices {
    padding: 40px 0;
    &__list{
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }
    &__price-head{
      padding: 18px 25px;
    }
    &__title{
      margin-bottom: 20px;
    }

    &__price-value{
      font-size: 22px;
    }

    &__price-item{
      margin-bottom: 25px;
      &:last-child{
        margin: 0;
      }
    }
  }
}