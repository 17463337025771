@font-face {
    src: url('https://fonts.googleapis.com/css2?family=Montserrat:opsz,wght@8..144,100;8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900;8..144,1000&display=swap');
    src: local('Montserrat'),
    url('Montserrat-VariableFont_wght.ttf') format('truetype');
    font-family:'Montserrat';
    font-style: normal;
    font-weight: 100 1000;
    font-stretch: 1% 500%;
}


