.analogs {
  &__icons{
    display: grid;
  }

  &__title{
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('large') {
  .analogs {
    padding-top: 100px;
    &__title{
      font-size: 32px;
      margin-bottom: 42px;
    }

    &__icons{
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 41px;
    }

    &__icon-container, &__icon-picture{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@include respond-up('medium') {
  .analogs {
  }
}

@include respond('medium') {
  .analogs {
    padding-top: 70px;
    &__title{
      font-size: calc(32/768 * 100vw);
    }
    &__icons{
      grid-row-gap: 42px;
      grid-column-gap: 32px;
      grid-template-columns: repeat(4, 1fr);
    }
    &__icon-container{
      justify-self: center;
      width: 100%;
      height: calc(63/768 * 100vw);
    }

    &__icon-image{
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-down('medium') {
  .analogs {
    &__title{
      margin-bottom: 30px;
    }
  }
}

@include respond-down('small') {
  .analogs {
    padding-top: 40px;
    &__title{
      font-size: calc(22/375 * 100vw);
      text-align: center;
    }

    &__icons{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 38px;
      grid-row-gap: 30px;
    }

    &__icon-container{
      width: 100%;
      height: calc(56/375 * 100vw);
    }

    &__icon-image{
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}