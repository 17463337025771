.delivery{
  &__title{
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #2A2A2F;
  }

  &__container{
    display: grid;
  }

  &__block{
    background: #FFFFFF;
    border-radius: 16px;
  }

  &__block-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__block-head{
    border-bottom: 1px solid #CFCFD7;
  }

  &__picture{
    display: flex;
  }

  &__block-head{
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: #1B3A6F;
  }

  &__item{
    &:first-child{
      border-right: 1px solid #CFCFD7;
      padding-right: 20px;
    }

    &:nth-child(2) {
      padding-left: 20px;
    }
  }

  &__item-subtitle{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #2A2A2F;
  }
}

@include respond-up('large') {
  .delivery{
    &__title{
      font-size: 40px;
    }

    &__container{
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    &__block{
      padding: 30px 34px;
    }

    &__block-head{
      padding-bottom: 21px;
    }

    &__item-container{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 35px;
      margin-bottom: 25px;
      &_high{
        .delivery{
          &__picture{
            height: 135px;
          }
        }
      }
    }

    &__picture{
      width: 180px;
      height: 110px;
    }
  }
}

@include respond-up('medium') {
  .delivery{
    &__title{
      margin-bottom: 30px;
    }
  }
}

@include respond('medium') {
  .delivery{
    &__title{
      font-size: calc(35/768 * 100vw);
    }

    &__container{
      row-gap: 18px;
    }

    &__block{
      padding: 25px 35px;
    }

    &__block-head{
      padding-bottom: 20px;
    }

    &__item-container{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 25px;
      &_high{
        .delivery{
          &__picture{
            height: calc(150/768 * 100vw);
          }
        }
      }
    }

    &__picture{
      width: calc(200/768 * 100vw);
      height: calc(122/768 * 100vw);
    }

    &__item-subtitle{
      font-size: calc(17/768 * 100vw);
    }
  }
}

@include respond('small') {
  .delivery{
    &__title{
      font-size: calc(26/375 * 100vw);
      margin-bottom: 20px;
    }

    &__container{
      row-gap: 18px;
    }

    &__block{
      padding: 17px 20px;
    }

    &__block-head{
      padding-bottom: 20px;
    }

    &__item-container{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 25px;
      &_high{
        .delivery{
          &__picture{
            height: calc(96/375 * 100vw);
          }
        }
      }
    }

    &__picture{
      width: calc(128/375 * 100vw);
      height: calc(78/375 * 100vw);
    }

    &__item-subtitle{
      font-size: calc(14/375 * 100vw);
    }
  }
}