.gallery {
  &__container{
    background: #fff;
    border-radius: 16px;
  }

  &__image-container{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .gallery {
    &__container{
      padding: 30px 35px;
      grid-gap: 19px;
    }

    &__image-container{
      height: 343px;
    }
  }
}

@include respond-up('medium') {
  .gallery {
    &__container{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
    }
  }
}

@include respond('medium') {
  .gallery {
    &__container{
      padding: 25px 35px;
      grid-gap: 16px;
    }
    &__image-container{
      padding-bottom: calc(240/768 * 100vw);
    }
  }
}

@include respond-down('medium') {
  .gallery {

  }
}

@include respond-down('small') {
  .gallery {
    &__container{
      display: grid;
      padding: 17px 20px;
      grid-gap: 16px;
    }
    &__image-container{
      padding-bottom: calc(240/375 * 100vw);
    }
  }
}