.footer {
  &__menu{
    display: flex;
    flex-direction: column;
  }

  &__menu-link, &__phone, &__email{
    display: inline-block;
    max-width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
  }

  &__copy, &__policy{
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #676767;
  }
}

@include respond-up('large') {
  .footer {
    padding-top: 60px;
    padding-bottom: 40px;
    &__menu-link{
      padding: 6px 0;
      &:first-child{
        padding-top: 0;
      }
    }

    &__top{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding-bottom: 90px;
    }

    &__bottom{
      display: flex;
    }

    &__contacts{
      display: flex;
      justify-content: flex-end;
      height: 20px;
    }

    &__phone{
      margin-right: 30px;
    }

    &__copy{
      margin-right: 154px;
    }
  }
}

@include respond-up('medium') {
  .footer {

  }
}

@include respond('medium') {
  .footer {
    padding: 70px 0 40px 0;

    &__top{
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 35px;
    }

    &__menu-link{
      padding: 6px 0;
      &:first-child{
        padding-top: 0;
      }
    }

    &__menu{
      padding-bottom: 107px;
    }

    &__phone{
      margin-right: 50px;
    }

    &__copy{
      margin-right: 60px;
    }

    &__bottom{
      display: flex;
    }
  }
}

@include respond-down('medium') {
  .footer {

  }
}

@include respond-down('small') {
  .footer {
    padding: 45px 0 40px 0;
    &__logo{
      margin-bottom: 19px;
    }

    &__menu-link{
      padding: 6px 0;
    }

    &__menu{
      margin-bottom: 45px;
    }

    &__phone{
      margin-right: 50px;
    }

    &__contacts{
      margin-bottom: 30px;
    }

    &__copy{
      margin-bottom: 18px;
    }
  }
}