:root {
  --modal-bg: rgba(0, 0, 0, 0.3);
  --modal-content-radius: 0px;
  --modal-content-shadow: none;
  --modal-content-padding: 30px;
  --modal-content-background: #fff;
  --modal-preloader-size: 30px;
}

.modal {
  &__bg {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    background: var(--t);
    transition: background .4s var(--teaser-transition-func);

    &._opened {
      background: var(--modal-bg);
    }

    &._closed {
      background: var(--t);
    }
  }

  &__layout {
    position: relative;
    z-index: 11000;
    cursor: default;
    margin: 0 auto;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-delay: 0s;
    transition: transform .4s var(--teaser-transition-func);
    &._opened {
      .modal{
        &__closer{
          pointer-events: all;
        }
      }
    }

    &._closed {
    }
  }

  &__container {
    border-radius: var(--modal-content-radius);
    box-shadow: var(--modal-content-shadow);
    position: relative;
    display: flex;
    align-items: center;
    background: var(--modal-content-background);

    &._opened {
    }

    &._closed {
    }
  }

  &__content {

  }

  &__closer {
    position: absolute;
    pointer-events: none;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
  }
}

body.modal-loading {
  overflow: hidden;
  //--preloader-size: 100px;

  //пульсирующий кружочек раньше был
  &__loader {
  }

  &__content {
    overflow: scroll;
    height: 100%;
    width: 100%;
  }
}

@include respond-up('large') {
  .modal {
    &__container {
      //width: calc(50vw + var(--grid-gap) / 2);
      //height: calc(var(--vh, 1vh) * 100);
      border-radius: 16px;

    }

    &__content {
      padding: 50px 25px;
      width: 655px;
    }

    &__layout {
      padding: 200px 0;
    }


    &__closer {
      top: 15px;
      right: 15px;
      width: 38px;
      height: 38px;
      display: block;

      &::before {
        content: '';
        width: 38px;
        height: 38px;
        display: block;
        background-image: url('../images/svg/closer.svg');
      }
    }
  }
}

@include respond-up('medium') {
  .modal {

  }
}

@include respond('medium') {
  .modal {
    &__container {
      border-radius: 16px;
    }

    &__content {
      padding: 50px 25px;
      width: 655px;
    }

    &__layout {
      padding: 200px 0;
    }


    &__closer {
      top: 15px;
      right: 15px;
      width: 38px;
      height: 38px;
      display: block;

      &::before {
        content: '';
        width: 38px;
        height: 38px;
        display: block;
        background-image: url('../images/svg/closer.svg');
      }
    }
  }
}

@include respond-down('medium') {
  .modal {
    &__layout {
      &._opened {
      }

      &._closed {
      }
    }

    &__content {
      padding: var(--grid-spacer);
      width: 100%;
      height: 100%;
    }

    &__container {
      min-height: 50vh;
      align-self: flex-end;
    }

    &__closer {

    }
  }
}

@include respond-down('small') {
  .modal {
    &__container {
      width: 100vw;
      height: 100vh;
    }

    &__content{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__closer {
      top: 30px;
      right: 18px;
      width: 38px;
      height: 38px;
      display: block;

      &::before {
        content: '';
        width: 38px;
        height: 38px;
        display: block;
        background-image: url('../images/svg/closer.svg');
      }
    }
  }
}