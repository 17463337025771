.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__code{
    color: var(--dark-blue);
    font-weight: bold;
  }

  &__button{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 10px;
    background-color: var(--dark-blue);
    padding: 12px 20px;
    border: none;
  }
}

@include respond-up('large') {
  .error-page {
    padding-top: 100px;
    margin-top: 170px;
    padding-bottom: 150px;
    &__code{
      font-size: 120px;
    }
  }
}

@include respond-up('medium') {
  .error-page {
    &__description {
      margin-top: 15px;
    }

    &__button {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .error-page {
    padding-top: 80px;
    margin-top: 200px;
    padding-bottom: 150px;
    &__code{
      font-size: 100px;
    }
  }
}

@include respond-down('small') {
  .error-page {
    padding-top: 54px;
    margin-top: 125px;
    padding-bottom: 100px;

    &__code{
      font-size: 90px;
    }

    &__button {
      margin-top: 30px;
    }
  }
}