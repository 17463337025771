.index-page {
  &__standarts{
    margin-top: 20px;
  }
}

@include respond-up('large') {
  .index-page {
    &__delivery, &__contacts, &__advantages, &__advantages-hero, &__gallery{
      padding-top: 100px;
    }

    &__advantages{
      padding-bottom: 80px;
    }

    &__contacts{
      padding-bottom: 80px;
    }
  }
}

@include respond-up('medium') {
  .index-page {

  }
}

@include respond('medium') {
  .index-page {
    &__delivery, &__contacts, &__advantages, &__advantages-hero, &__gallery{
      padding-top: 70px;
    }

    &__contacts{
      padding-bottom: 57px;
    }

    &__advantages{
      padding-bottom: 50px;
    }
  }
}

@include respond-down('medium') {
  .index-page {

  }
}

@include respond-down('small') {
  .index-page {
    &__delivery, &__contacts, &__advantages, &__advantages-hero, &__gallery{
      padding-top: 50px;
    }
    &__contacts{
      padding-bottom: 50px;
    }

    &__advantages{
      padding-bottom: 40px;
    }
  }
}