.policy-checkbox {
  position: relative;

  input {
    position: absolute;
    width: 16px;
    height: 16px;

    &:checked + label {
      &:before {
        background-image: url("../images/svg/policy-checkbox-checked.svg");
      }
    }
  }

  label {
    display: block;
    font-style: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 145%;
    letter-spacing: -0.02em;
    padding-left: 25px;
    color: #676767;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-image: url("../images/svg/policy-checkbox-unchecked.svg");
      transition: background 0.2s;
    }
  }

  &__policy-link {
    color: #2A2A2F;
    width: max-content;
  }

  &_dark {
    input {
      &:checked + label {
        &:before {
          //background-image: url("../images/svg/checkbox-checked-dark.svg");
        }
      }
    }

    label {
      color: var(--grey-text-color);

      &:before {
        //background-image: url("../images/svg/checkbox-unchecked-dark.svg");
      }
    }

    .policy-checkbox {
      &__policy-link {
        color: var(--black-color);
      }
    }
  }
}

@include respond-up('large') {
  .policy-checkbox {
    &__policy-link {
      border-bottom: 1px solid var(--t);
      transition: border-bottom-color .3s;

      &:hover {
        border-bottom-color: #fff;
      }
    }

    &_dark {
      .policy-checkbox {
        &__policy-link {
          &:hover {
            border-bottom-color: var(--black-color);
          }
        }
      }
    }
  }
}
