.request-form {
  position: relative;

  &__data {
    position: relative;
    z-index: 1;
    transition: all 0.4s;
  }

  &__success {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 100;
    transition: {
      property: opacity, transform, visibility;
      duration: 0.4s;
    };
    display: flex;
    //align-items: center;
    //justify-content: center;
    //transform: translate3d(0,40px,0);
  }

  &.success {
    .recall-form {
      &__main {
        //opacity: 0.05;
        opacity: 0;
      }

      &__success {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        background: var(--background-color);
        height: min-content;
      }
    }
  }
}

@include respond('medium') {
  .request-form {
    &.success {
      .request-form {
        &__success {
          padding-top: 127px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .request-form {
    &.success {
      .request-form {
        &__success {
          padding-top: 140px;
        }
      }
    }
  }
}