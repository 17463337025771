.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 4700;
  background: white;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);

  &__mail,&__schedule{
    display: flex;
    align-items: flex-end;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
  }

  &__mail-icon, &__schedule-icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  &__phone{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #2A2A2F;
  }

  &__button{
    background-color: #1B3A6F;
    border-radius: 10px;
    max-width: 170px;
  }

  &__recall-link{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    text-transform: uppercase;
    display: flex;
  }
}

@include respond-up('large') {
  .header{
    &._sticky{
      position: sticky;
      top: 0;
      .header{
        &__bottom{
          opacity: 0;
          pointer-events: none;
        };
      }
    }
    &__wrap {
      position: relative;
      width: 100%;
    }

    &__top{
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__recall-link{
      padding: 13px 21px;
      &_mobile{
        display: none;
      }
    }

    &__bottom{
      position: absolute;
      left: 0;
      right: 0;
      height: 50px;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 135%;
      letter-spacing: -0.02em;
      color: #2A2A2F;
      display: flex;
      align-items: center;
      transition: opacity .2s;
      background-color: #FFFFFF;
      opacity: 1;
      pointer-events: all;
    }

    &__menu{
      display: flex;
      align-items: center;
      align-self: center;
    }

    &__menu-link{
      padding: 0 20px;
      border-right: 1px solid #CFCFD7;
      &:last-child{
        border: none;
      }
    }

    &__drop-menu{
      display: none;
    }

    &__hamburger-wrap{
      display: none;
    }

    &__button{
      transition: background-color .3s;
      &:hover{
        background: #041D47;
      }
    }
  }
}

@include respond('medium'){
  .header{
    &__recall-link{
      &_mobile{
        width: 46px;
        height: 46px;
      }
    }

    &__top{
      grid-template-columns: 30px 1fr 133px 46px;
      grid-column-gap: 30px;
    }

    &__wrap{
      height: 62px;
    }

    &__phone{
      justify-self: flex-end;
    }

    &__button{
      justify-self: flex-end;
    }
  }
}

@include respond-down('medium'){
  .header{
    &._opened{
      background-color: white;
      .header{
        &__drop-menu{
          opacity: 1;
          pointer-events: all;
          z-index: 9000;
        }
      }
    }

    &__drop-menu{
      position: absolute;
      top: 55px;
      left: 0;
      right: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity .6s;
      background: white;
    }

    &__mail{
      display: none;
    }
    &__schedule{
      display: none;
    }

    &__bottom{
      display: none;
    }

    &__top{
      height: 100%;
      display: grid;
      align-items: center;
    }

    &__recall-link{
      display: none;
      &_mobile{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@include respond-down('small'){
  .header{
    &__top{
      grid-template-columns: 26px 1fr 40px ;
      grid-column-gap: 20px;
    }
    &__logo{
      display: flex;
      align-items: center;
      svg{
        width: 179px;
        height: 22px;
      }
    }
    &__wrap{
      height: 56px;
    }
    &__phone{
      display: none;
    }

    &__recall-link{
      &_mobile{
        width: 40px;
        height: 40px;
      }
    }
  }
}