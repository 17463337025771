.modal-form-success {
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #3066A2;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #676767;
  }
}

@include respond-up('large') {
  .modal-form-success {

  }
}

@include respond-up('medium') {
  .modal-form-success {
    padding: 50px 0 112px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon{
      margin-right: 25px;
    }
    &__title {
      font-size: 26px;
      margin-bottom: 13px;
    }

    &__subtitle {
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .modal-form-success {

  }
}

@include respond-down('small') {
  .modal-form-success {
    &__title {
      font-size: 20px;
      margin-top: 20px;
    }

    &__subtitle {
      margin-top: 20px;
      font-size: 16px;
    }

    &__icon {
      height: 82px;

      svg {
        width: 82px;
        height: 82px;
      }
    }
  }
}