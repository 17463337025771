.standarts {
  background-color: #fff;
  border-radius: 16px;
  display: grid;

  &__icons{
    display: grid;
  }

  &__subtitle{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--black-text-color);
  }
}

@include respond-up('large') {
  .standarts {
    padding: 60px 48px;
    grid-template-columns: repeat(21, 1fr);
    grid-column-gap: 16px;

    &__icons{
      grid-column: 2 / 11;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 60px;
    }

    &__icon-container{
      &:nth-child(5) {
        grid-column: 1 / 3;
        justify-self: center;
      }
    }

    &__icon-container, &__icon-picture{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__info{
      grid-column: 14 / 21;
      margin-left: -17px;
    }

    &__title-icon{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 63px;
    }
  }
}

@include respond-up('medium') {
  .standarts {

  }
}

@include respond('medium') {
  .standarts {
    padding: 50px 50px 70px 50px;

    &__info{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      margin-bottom: 110px;
    }

    &__title-icon{
      svg{
        width: calc(266/768 * 100vw);
        height: calc(74/768 * 100vw)
      }
    }

    &__subtitle{
      font-size: calc(17/768 * 100vw);
    }

    &__icons{
      grid-row-gap: 47px;
      grid-column-gap: 57px;
      grid-template-areas:
              "a a b b c c"
              "d d d e e e";
    }

    &__icon-container{
      justify-self: center;
      width: 100%;
      height: calc(60/768 * 100vw);
      &:nth-child(1) {
        grid-area: a;
      }

      &:nth-child(2) {
        grid-area: b;
      }

      &:nth-child(3) {
        grid-area: c;
      }

      &:nth-child(4) {
        grid-area: d;
        margin-left: 115px;
      }

      &:nth-child(5) {
        grid-area: e;
        margin-right: 100px;
      }
    }

    &__icon-image{
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-down('medium') {
  .standarts {
    grid-template-rows: repeat(2, auto);
    &__info{
      grid-row: 1 / 2;
    }

    &__icons{
      grid-row: 2 / 3
    }
  }
}

@include respond-down('small') {
  .standarts {
    padding: 30px 20px 53px 20px;
    &__subtitle{
      font-size: calc(16/375 * 100vw);
      padding-bottom: 64px;
    }
    &__title-icon{
      padding-bottom: 30px;
      svg{
        width: calc(229/375 * 100vw);
        height: calc(64/375 * 100vw);
      }
    }

    &__icons{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 35px;
      grid-row-gap: 40px;
    }

    &__icon-container{
      width: 100%;
      height: calc(46/375 * 100vw);
      &:nth-child(5) {
        grid-column: 1 / 3;
        justify-self: center;
      }
    }

    &__icon-image{
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}