.table-tech{
  &__title{
    display: block;
  }
  &__row{
    display: flex;
    border-radius: 4px;
    border-bottom: 2px solid #fff;
    &:first-child{
      background-color: #3066A2;
      text-transform: uppercase;
      .table-tech{
        &__ceil{
          color: #FFFFFF;
          font-weight: 600 !important;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &:nth-child(2n+2) {
      background-color: #F3F3F6;
    }

    &:nth-child(2n+3) {
      background-color: #E8E8ED;
    }
  }

  &__ceil{
    border-right: 2px solid #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
    &:first-child{
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 135%;
      letter-spacing: -0.02em;
      color: #1B3A6F;
    }
    &:last-child{
      border: none;
    }
  }

  &__row-add{
    border-radius: 4px;
    &:first-child{
      background-color: #F3F3F6;
      color: #2A2A2F;
      margin-bottom: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 135%;
      letter-spacing: -0.02em;
    }
    &:last-child{
      position: relative;
      background-color: #3066A2;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 135%;
      letter-spacing: -0.02em;
      color: #FFFFFF;
    }
  }

  &__text-black{
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
  }

  &__row-add-container{
    display: grid;
  }

  &__row-side{
    display: flex;
  }

  &__iso-item{
    border-right: 1px solid #FFFFFF;
    padding: 0 20px;
    &:first-child{
      padding-left: 0;
    }
    &:last-child{
      border: none;
      padding-right: 0;
    }
  }

  &__din-item{
    margin-left: 30px;
  }
}

@include respond-up('large') {
  .table-tech{
    padding: 90px 0;
    &__title{
      margin-bottom: 25px;
    }
    &__row{
      padding-right: 20px;
    }
    &__ceil{
      padding: 15px 20px;
      &:first-child{
        width: 427px;
      }
      &:nth-child(2){
        width: 312px;
      }
      &:nth-child(3){
        width: 109px;
      }
      &:nth-child(4){
        width: 108px;
      }
      &:nth-child(5){
        width: 108px;
      }
    }

    &__add{
      margin-top: 25px;
    }
    &__row-add{
      padding: 15px 20px;
      &:last-child{
        &:before{
          content: '';
          position: absolute;
          width: 2px;
          height: 50px;
          left: 524px;
          background-color: #FFFFFF;
          top: 0;
        }
      }
    }

    &__row-add-container{
      grid-template-columns: 524px 576px;
    }

    &__iso-block{
      margin-left: 30px;
    }
  }
}

@include respond('medium') {
  .table-tech{
    padding: 70px 0;
    &__title{
      margin-bottom: 25px;
    }
    &__row{
      display: grid;
      grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
      &:nth-child(4) {
        .table-tech{
          &__ceil{
            &:first-child{
              word-break: break-all !important;
            }
          }
        }
      }
    }
    &__ceil{
      padding: 10px 15px;
      font-size: calc(12/768 * 100vw);
      word-break: normal;
      &:first-child{
        font-size: calc(12/768 * 100vw);
      }
    }

    &__add{
      margin-top: 20px;
    }

    &__row-add{
      &:first-child{
        padding: 9px 10px;
      }

      &:last-child{
        padding: 14px 10px;
      }
    }

    &__iso-block{
      margin-left: 30px;
    }

    &__row-side{
      &:first-child{
        margin-bottom: 8px;
      }
    }
  }
}

@include respond('small') {
  .table-tech{
    padding: 50px 0;
    &__title{
      margin-bottom: 20px;
    }
    &__row{
      display: grid;
      grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
      &:nth-child(4) {
        .table-tech{
          &__ceil{
            &:first-child{
              word-break: break-all !important;
            }
          }
        }
      }
    }
    &__ceil{
      padding: 6px 10px;
      font-size: calc(10/375 * 100vw);
      word-break: normal;
      &:first-child{
        font-size: calc(10/375 * 100vw);
      }
    }

    &__add{
      margin-top: 20px;
    }

    &__row-add{
      font-size: calc(12/375 * 100vw) !important;
      &:first-child{
        padding: 9px 10px;
      }

      &:last-child{
        padding: 14px 10px;
      }
    }

    &__text-black{
      font-size: calc(12/375 * 100vw) !important;
    }

    &__row-side{
      justify-content: space-between;
      &:first-child{
        margin-bottom: 8px;
      }
    }

    &__iso-item{
      padding: 0 15px;
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        border: none;
        padding-right: 0;
      }
    }
  }
}

