.drop-menu{
  padding: 25px 20px 40px 20px;
  box-shadow: 0px 16px 13px rgba(0, 0, 0, 0.2);
  &__top{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  &__list{
    display: flex;
    flex-direction: column;
  }

  &__item-link{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px 0;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
    border-bottom: 1px solid #CFCFD7;
    &:last-child{
      border-bottom: none;
    }
  }

  &__bottom{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
  }

  &__mail, &__schedule{
    display: flex;
    align-items: center;
  }

  &__mail{
    margin-bottom: 20px;
  }

  &__mail-icon, &__schedule-icon{
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
}