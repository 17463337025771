.product {
  &__pre-title{
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 115%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #3066A2;
    text-align: center;
  }

  &__title{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 125%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #2A2A2F;

  }

  &__block{
    display: flex;
  }

  &__image-block{
    background-color: #3066A2;
    border-radius: 16px;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__about{
    background: #F3F3F6;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }

  &__about-item{
    display: flex;
    align-items: flex-start;
  }

  &__about-title{
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: #3066A2;
    margin-bottom: 10px;
  }

  &__about-subtitle &__link{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #676767;
  }

  &__documents{
    align-items: center;
    justify-content: center;
  }

  &__document-item{
    &:first-child{
      margin-bottom: 10px;
    }
  }

  &__document-link{
    display: flex;
    align-items: center;
    &:hover{
      .product{
        &__document-name{
          color: #3066A2;
          border-bottom: 1px dotted #3066A2;
        }
      }
    }
  }

  &__document-name{
    border-bottom: 1px dotted #676767;
    padding-bottom: 1px;
    display: inline-block;
    margin-left: 10px;
    transition: color .2s;
  }
}

@include respond-up('large') {
  .product {
    padding-top: 60px;
    &__pre-title{
      padding-bottom: 16px;
    }
    &__title{
      padding-bottom: 30px;
    }
    &__image-block{
      flex: 1 1 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 68px 0;
      z-index: 1;
      margin-right: -20px;
    }

    &__image-container{
      width: 242px;
      height: 332px;
    }

    &__picture{
      display: flex;
    }

    &__about{
      flex: 1 1 52%;
      padding: 50px 45px 65px 55px;
    }
  }
}

@include respond-up('medium') {
  .product {
    &__about-item{
      padding-bottom: 45px;
    }

    &__about-icon{
      margin-right: 30px;
    }
  }
}

@include respond('medium') {
  .product {
    padding-top: 50px;
    &__pre-title{
      font-size: calc(13/768 * 100vw);
      padding-bottom: 17px;
    }
    &__title{
      padding-bottom: 34px;
      font-size: calc(35/768 * 100vw);
    }
    &__image-block{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: calc(29/768 * 100vw) 0;
      margin-bottom: -25px;
      z-index: 1;
    }
    &__image-container{
      width: calc(338/768 * 100vw);
      height: calc(442/768 * 100vw);
    }

    &__about{
      padding: 80px 40px 40px 40px;
    }
  }
}

@include respond-down('medium') {
  .product {
    &__block{
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .product {
    padding-top: 40px;
    &__pre-title{
      padding-bottom: 12px;
      font-size: calc(12/375 * 100vw);
    }
    &__title{
      padding-bottom: 34px;
      font-size: calc(25/375 * 100vw);
    }
    &__image-block{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: calc(29/375 * 100vw) 0;
      margin-bottom: -25px;
      z-index: 1;
    }
    &__image-container{
      width: calc(158/375 * 100vw);
      height: calc(207/375 * 100vw);
    }

    &__about{
      padding: 50px 20px 20px 20px;
    }

    &__about-item{
      padding-bottom: 30px;
    }

    &__about-icon{
      margin-right: 20px;
    }
  }
}