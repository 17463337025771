.table-analogs{
  &__title{
    display: block;
  }
  &__row{
    border-radius: 4px;
    margin-bottom: 4px;
    &:first-child{
      background-color: #3066A2;
      text-transform: uppercase;
      .table-analogs{
        &__ceil{
          color: #FFFFFF;
          font-weight: 600 !important;
        }
      }
    }

    &:nth-child(2) {
      background-color: #1B3A6F;
      .table-analogs{
        &__ceil{
          color: #FFFFFF;
          font-weight: 600 !important;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &:nth-child(2n+4) {
      background-color: #F3F3F6;
    }

    &:nth-child(2n+3) {
      background-color: #FFF;
    }
  }

  &__ceil{
    border-right: 2px solid #E6E5EB;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
    &:first-child{
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 135%;
      letter-spacing: -0.02em;
      color: #1B3A6F;
    }
    &:last-child{
      border: none;
    }
  }
}

@include respond-up('large') {
  .table-analogs{
    padding-top: 80px;
    &__row{
      padding-right: 20px;
      display: grid;
      grid-template-columns: 252px 1fr 1fr;
    }
    &__ceil{
      padding: 15px 20px;
    }
  }
}

@include respond-up('medium') {
  .table-analogs{
    &__title{
      margin-bottom: 25px;
    }
    &__ceil{
      &.with-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    &__small-ceil{
      &:nth-child(2) {
        border-left: 2px solid #E6E5EB;
        padding-left: 20px;
      }
    }
  }
}

@include respond('medium') {
  .table-analogs{
    padding-top: 50px;
    &__row{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      &:nth-child(4) {
        .table-analogs{
          &__ceil{
            &:first-child{
              word-break: break-all !important;
            }
          }
        }
      }
    }
    &__ceil{
      padding: 10px 15px;
      font-size: calc(12/768 * 100vw);
      word-break: normal;
      &:first-child{
        font-size: calc(12/768 * 100vw);
      }
    }
  }
}

@include respond('small') {
  .table-analogs{
    padding-top: 40px;
    &__title{
      margin-bottom: 20px;
    }
    &__row{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      &:nth-child(4) {
        .table-analogs{
          &__ceil{
            &:first-child{
              word-break: break-all !important;
            }
          }
        }
      }
    }
    &__ceil{
      padding: 6px 10px;
      font-size: calc(10/375 * 100vw);
      word-break: normal;
      &:first-child{
        font-size: calc(10/375 * 100vw);
      }
    }
  }
}

