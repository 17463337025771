.contacts{
  &__title{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 125%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #2A2A2F;
  }

  &__container{
    background: #FFFFFF;
    border-radius: 16px;
    display: grid;
  }

  &__map{
    border-radius: 16px;
    overflow: hidden;
  }

  &__info{
    display: grid;
  }

  &__phone-link{
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
  }

  &__label{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #3066A2;
  }

  &__button{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background-color: #3066A2;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    text-transform: uppercase;
  }

  &__text{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #2A2A2F;
  }
}

@include respond-up('large') {
  .contacts{
    &__container{
      padding: 40px 40px 55px 40px;
    }

    &__map{
      height: 488px;
      margin-top: -25px;
    }

    &__info{
      grid-template-columns: 210px 1fr;
      grid-column-gap: 72px;
    }

    &__text-block{
      display: grid;
      grid-template-columns: 1fr 490px;
    }

    &__address{
      padding-left: 50px;
      border-left: 1px solid #CFCFD7;
    }

    &__phone-link{
      margin-bottom: 20px;
    }
  }
}

@include respond-up('medium') {
  .contacts{
    &__title{
      padding-bottom: 30px;
    }

    &__label{
      padding-bottom: 16px;
    }

    &__button{
      padding: 18px 30px;
    }
  }
}

@include respond('medium') {
  .contacts{
    &__title{
      font-size: calc(35/768 * 100vw);
    }
    &__container{
      padding: 37px 40px 75px 40px;
    }

    &__info{
      grid-template-columns: 1fr 1fr;
    }

    &__phone-link{
      font-size: 24px;
      padding-bottom: 20px;
    }

    &__button{
      max-width: 250px;
    }

    &__email{
      margin-bottom: 30px;
    }

    &__map{
      height: 500px;
      margin-top: -30px;
    }
  }
}

@include respond-down('small') {
  .contacts{
    &__title{
      font-size: calc(26/375 * 100vw);
      padding-bottom: 20px;
    }

    &__container{
      padding: 30px 20px 58px 20px;
    }

    &__phone-link{
      font-size: 22px;
      margin-bottom: 12px;
    }

    &__button{
      font-size: 13px;
      padding: 16px 30px;
      max-width: 200px;
      margin-bottom: 30px;
    }

    &__label{
      font-size: 15px;
      padding-bottom: 12px;
    }

    &__text{
      font-size: 16px;
    }

    &__email{
      margin-bottom: 25px;
    }

    &__map{
      height: 420px;
      margin-top: -20px;
    }
  }
}